<template>
  <settings-layout>
    <template #actions>
      <b-button size="sm" variant="link" class="p-0 mr-1" @click="newSetting.visible = !newSetting.visible">
        <feather-icon icon="PlusIcon" size="16" class="align-middle text-body"/>
      </b-button>
    </template>

    <template #content>
      <b-row v-if="newSetting.visible" class="setting-row bg-light-primary">
        <b-col cols="12" class="setting-col setting-header">
          <div class="setting-wrapper">
            <div class="setting-title"><strong>New Setting</strong></div>
          </div>
          <div class="setting-state">
            <b-button size="sm" variant="primary" @click="createSetting(newSetting)">Create</b-button>
          </div>
        </b-col>
        <b-col cols="12" class="setting-col border-0">
          <b-input v-model="newSetting.key" size="sm" placeholder="New Setting Key"></b-input>
        </b-col>
        <b-col cols="12" class="setting-col pt-0">
          <b-textarea v-model="newSetting.description" size="sm" placeholder="Setting Description"></b-textarea>
        </b-col>
      </b-row>


      <b-card-actions v-for="setting in sortedSettings" :key="setting.key"
                      :title="setting.key" :sub-title="setting.description"  action-collapse action-options collapsed
                      card-class="border shadow-none mb-1" header-class="py-0">
        <template #options>
          <b-dropdown-item @click="deleteSetting(setting.key)">
            <feather-icon icon="TrashIcon" class="ml-1"/>
            <span class="align-middle ml-50">Delete</span>
          </b-dropdown-item>
        </template>

        <b-textarea v-model="setting.description" :rows="1" size="sm" placeholder="Setting Description" class="form-control-plaintext"></b-textarea>

        <vue-json-editor v-model="setting.value"
                         :mode="setting.mode"
                         :show-btns="true"
                         :expanded-on-start="true"
                         @json-save="updateSetting(setting)" />
      </b-card-actions>

    </template>

    <template #debug>
      <debug title="setting" collapsed class="mt-2">{{ settings }}</debug>
    </template>
  </settings-layout>
</template>

<script>
import {API, graphqlOperation} from 'aws-amplify';
import {listSettings} from '@/graphql/queries';
import {createSetting, deleteSetting, updateSetting} from '@/graphql/mutations';
import vueJsonEditor from 'vue-json-editor'
import {onCreateSetting, onDeleteSetting} from '@/graphql/subscriptions';
import notify from '@/mixins/notify.mixin';
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
import SettingsLayout from '@/views/management/settings/SettingsLayout.vue';

export default {
  name: 'Settings',
  components: {
    SettingsLayout,
    BCardActions,
    vueJsonEditor
  },
  mixins: [ notify ],
  data() {
    return {
      newSetting: {
        visible: false,
        key: '',
        value: {},
        description: ''
      },
      settings: [ ],
      icon: 'fas fa-gear'
    }
  },
  async created() {
    await this.listSettings();
    this.onCreateSetting();
    this.onDeleteSetting();
  },
  computed: {
    sortedSettings() {
      return this.settings.filter(item => item).sort((a, b) => a.key.localeCompare(b.key))
    }
  },
  methods: {
    async listSettings() {
      const response = await API.graphql(graphqlOperation(listSettings));
      this.settings = response.data.listSettings.items;
      this.settings.forEach(setting => {
        setting.value = JSON.parse(setting.value)
        setting.mode = 'code'
      })
    },
    async createSetting(setting) {
      try {
        const settingInput = {
          key: setting.key,
          value: JSON.stringify(setting.value),
          description: setting.description
        }
        await API.graphql(graphqlOperation(createSetting, { input: settingInput }));
        this.notify({ title: 'Success', text: 'Setting was successfully created', icon: this.icon, variant: 'success' });


        this.newSetting.key = null
        this.newSetting.value = {}
        this.newSetting.description = null
      }
      catch (err) {
        console.error(err)
        this.notify({ title: 'Error', text: 'Setting failed to create', icon: this.icon, variant: 'danger'});
      }
    },
    async updateSetting(setting) {
      try {
        const settingInput = {
          key: setting.key,
          value: JSON.stringify(setting.value),
          description: setting.description
        }
        await API.graphql(graphqlOperation(updateSetting, { input: settingInput }));
        this.notify({ title: 'Success', text: 'Setting was successfully updated', icon: this.icon, variant: 'success' });
      }
      catch (err) {
        console.error(err)
        this.notify({ title: 'Error', text: 'Setting failed to update', icon: this.icon, variant: 'danger'});
      }
    },
    async deleteSetting(key) {
      try {
        const response = await API.graphql(graphqlOperation(deleteSetting, { input: { key: key } } ));
        this.settings = this.settings.filter(item => item.id !== response.data.deleteSetting.id);
        this.notify({ title: 'Success', text: 'Setting was successfully deleted', icon: this.icon, variant: 'success' });
      }
      catch(error) {
        console.error(error)
        this.notify({ title: 'Error', text: 'Setting failed to delete', icon: this.icon, variant: 'danger'});
      }
    },
    onCreateSetting() {
      API.graphql(graphqlOperation(onCreateSetting)).subscribe((sourceData) => {
        const setting = sourceData.value.data.onCreateSetting
        if (setting) {
          setting.mode = 'code'
          this.settings = [setting, ...this.settings];
        }
      });
    },
    onDeleteSetting() {
      API.graphql(graphqlOperation(onDeleteSetting)).subscribe(() => {
        this.listSettings()
      });
    },
  }
}
</script>


<style lang="scss">
  @import '@/assets/scss/nyssma/settings.scss';

  $primary: #212969;
  $border-color: #ebe9f1;

  .jsoneditor {
    border: 1px solid #ebe9f1!important;
    border-radius: 0.357rem;
    padding: .25em;
    margin-top: 1rem;

    .jsoneditor-menu {
      background: $primary;

      a {
        display: none !important;
      }

      button {
        &:disabled {
          background: rgba(255,255,255,0.2);
        }
      }

      .jsoneditor-contextmenu {
        ul {
          background: #fff;
          border: 1px solid #ebe9f1!important;
          border-radius: 0.357rem;

          li {
            cursor: pointer;
            &:hover {
              background: rgba(33, 41, 105, 0.12);
            }

            .jsoneditor-selected {
              background: $primary;
            }

          }
        }
      }

    }
    .jsoneditor-outer {

      > * {
        min-height: 300px;
      }

      .jsoneditor-tree {
        .jsoneditor-menu {
          background: #fff;
          border: 1px solid #ebe9f1!important;
          border-radius: 0.357rem;

          .jsoneditor-selected {
            background: $primary;
          }
        }
      }
    }
  }

  .jsoneditor-btns {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
    .json-save-btn {
      margin-left: 0.5rem;
      background: $primary;
    }
  }

</style>


